/**
 * ES Module for Firebase Auth Service
 * Author: Vrijraj Singh - https://vrijraj.xyz
 * Firebase SDK Version: 9
*/
import {
    signOut,
    signInWithPopup,
    GoogleAuthProvider,
    onAuthStateChanged
} from "firebase/auth";

// import { db } from "@/config/firebase";
// import { doc, getDoc } from "firebase/firestore";
import { auth } from "@/config/firebase";
// import router from "@/router";

const state = {
    currentUser: null
}

const getters = {
    getUserInfo: (state) => state.currentUser,
}

const mutations = {
    setUserInfo: (state, payload) => state.currentUser = payload,
    clearUserInfo: (state) => state.currentUser = null,
    doNothing: (state)=>(state)
}

const actions = {
    /**
     * @description Login with Google
     * @param {*} param0 
     * @returns {Object} - Object
    */
    async LoginWithGoogle({ commit }) {
        const provider = new GoogleAuthProvider();
        try {
            let userInfo = await signInWithPopup(auth, provider);
            commit('setUserInfo', userInfo.user)
            return {
                success: true,
                data: userInfo.user,
            };
        } catch (error) {
            return {
                success: false,
                msg: error,
            }
        }
    },

    /**
     * @description Fetch Current User
     * @returns {Object} - Object
    */
    FetchCurrentUser({commit}) {
        return new Promise((resolve, reject)=>{
            onAuthStateChanged(auth, (user)=>{
                if(user){
                    commit('setUserInfo', user)
                    resolve()
                }else{
                    commit("clearUserInfo");
                    reject()
                }
            })
        })
    },

    /**
     * @description Logout User
     * @param {*} param0 
     * @returns {Object} - Object
    */
    async LogoutUser({ commit }) {
        try {
            await signOut(auth);
            commit('clearUserInfo')
            // router.push('/login')
            return {
                success: true,
                message: "User Logout Successfully",
            };
        } catch (error) {
            return {
                success: false,
                msg: error,
            }
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};