import Vue from "vue";
import Vuex from "vuex";
import propertiesStore from "./modules/properties.store";
import authStore from "./modules/auth.store";
import brandRequirementStore from "./modules/brandRequirement.store";


Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    drawer: false,
  },
  getters: {},
  mutations: {
    SET_DRAWER: (state, payload) => (state.drawer = payload),
    TOGGLE_DRAWER: (state) => (state.drawer = !state.drawer),
  },
  actions: {},
  modules: {
    propertiesStore,
    authStore,
    brandRequirementStore
  },
});
