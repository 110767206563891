<template>
  <v-navigation-drawer
    temporary
    v-model="drawer"
    app
    width="300px"
    style="
      border: 1px solid #e0e0e0 !important;
      -webkit-backdrop-filter: saturate(100%) blur(5px);
      backdrop-filter: saturate(100%) blur(10px);
      background-color: rgba(255, 255, 255, 0.7);
    "
  >
    <v-list-item class="py-3">
      <v-list-item-content>
        <v-list-item-title>
          <v-img
            alt="Vuetify Name"
            class="shrink mt-1"
            contain
            min-width="80"
            src="https://raw.githubusercontent.com/agprop-india/agprop-data/81b26e4b4b66d6028ea8c36cded435f5a7260d67/images/agprop%20logo.svg"
            lazy-src="https://raw.githubusercontent.com/agprop-india/agprop-data/81b26e4b4b66d6028ea8c36cded435f5a7260d67/images/agprop%20logo.svg"
            width="50"
          />
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>
    <v-list dense shaped>
      <!-- Home -->
      <v-list-item to="/" dense color="#0048d0">
        <!-- <v-list-item-icon>
          <v-icon>mdi-home-outline</v-icon>
        </v-list-item-icon> -->
        <v-list-item-title>Home</v-list-item-title>
      </v-list-item>
      <!-- Home -->

      <!-- Properties -->
      <v-list-item to="/properties" dense color="#0048d0">
        <!-- <v-list-item-icon>
          <v-icon>mdi-home-outline</v-icon>
        </v-list-item-icon> -->
        <v-list-item-title>Properties</v-list-item-title>
      </v-list-item>
      <!-- Properties -->

      <!-- Products -->
      <v-list-item to="/products" dense color="#0048d0">
        <!-- <v-list-item-icon>
          <v-icon>mdi-home-outline</v-icon>
        </v-list-item-icon> -->
        <v-list-item-title>Products</v-list-item-title>
      </v-list-item>
      <!-- Products -->

      <!-- Brand Requirements -->
      <v-list-item to="/requirements" dense color="#0048d0">
        <!-- <v-list-item-icon>
          <v-icon>mdi-home-outline</v-icon>
        </v-list-item-icon> -->
        <v-list-item-title>Brand Requirements</v-list-item-title>
      </v-list-item>
      <!-- Brand Requirements -->

      <!-- Services -->
      <v-list-item to="/services" dense color="#0048d0">
        <!-- <v-list-item-icon>
          <v-icon>mdi-home-outline</v-icon>
        </v-list-item-icon> -->
        <v-list-item-title>Services</v-list-item-title>
      </v-list-item>
      <!-- Services -->

      <!-- About -->
      <v-list-item to="/about" dense color="#0048d0">
        <!-- <v-list-item-icon>
          <v-icon>mdi-home-outline</v-icon>
        </v-list-item-icon> -->
        <v-list-item-title>About</v-list-item-title>
      </v-list-item>
      <!-- About -->

      <!-- Contact -->
      <v-list-item to="/contact" dense color="#0048d0">
        <!-- <v-list-item-icon>
          <v-icon>mdi-home-outline</v-icon>
        </v-list-item-icon> -->
        <v-list-item-title>Contact</v-list-item-title>
      </v-list-item>
      <!-- Contact -->

      <!-- Team -->
      <!-- <v-list-item to="/team" dense color="#0048d0">
        <v-list-item-icon>
          <v-icon>mdi-home-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Team</v-list-item-title>
      </v-list-item> -->
      <!-- Team -->
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "AppDrawer",
  computed: {
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
  },
};
</script>

<style></style>
