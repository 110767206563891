<template>
  <v-footer style="background-color: #000000;color: white;">
    <v-main class="my-0 pt-5">
      <v-container fluid>
        <v-row justify="center" align="center">
          <v-col md="10">
            <!-- Content -->
            <v-container fluid class="mb-3">
                <v-row>
                    <v-col md="3">
                        <p>agprop</p>
                        <p style="font-size: 90%;">011-22540111 <br> info@agprop.in <br> E-367, 1st Floor, Nirman Vihar, New Delhi</p>
                    </v-col>
                    <v-col md="2">
                        <p style="font-weight: 600;">About</p>
                        <router-link class="footer-link mb-0" to="/services">Services</router-link><br>
                        <router-link class="footer-link mb-0" to="/products">Products</router-link><br>
                        <router-link class="footer-link mb-0" to="/brand-requirements">Brand Requirements</router-link><br>
                        <a class="footer-link mb-0" to="/products">Work with us</a>
                    </v-col>
                    <v-col md="2">
                        <p style="font-weight: 600;">Services</p>
                        <router-link class="footer-link mb-0" to="/services">Investment Advisory </router-link> <br>
                        <router-link class="footer-link mb-0" to="/services">Asset Development </router-link><br>
                        <router-link class="footer-link mb-0" to="/services">Leasing </router-link><br>
                        <router-link class="footer-link mb-0" to="/services">Legal Services</router-link>
                    </v-col>
                    <v-col md="2">
                        <p style="font-weight: 600;">Others</p>
                        <router-link class="footer-link mb-0" to="/terms-conditions">Terms and Conditions</router-link> <br>
                        <router-link class="footer-link mb-0" to="/privacy-policy">Privacy Policy</router-link>
                    </v-col>
                </v-row>
            </v-container>
            <!-- Content -->
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-footer>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>

<style scoped>
.footer-link{
  text-decoration: none;
  color: white;
  font-size: 90%;
}
</style>