<template>
  <v-app-bar
    app
    flat
    class="px-2 mx-2 mt-3 mx-md-auto"
    style="
      align-items: center;
      max-width: 1024px;
      margin: auto;
      margin-top: 1%;
      border-radius: 16px;
      -webkit-backdrop-filter: saturate(100%) blur(5px);
      backdrop-filter: saturate(100%) blur(10px);
      background-color: rgba(255, 255, 255, 0.7);
    "
  >
    <v-app-bar-nav-icon
      aria-label="Hamburger Btn"
      @click="toggleDrawer"
      class="d-md-none d-lg-none"
    ></v-app-bar-nav-icon>
    <router-link to="/">
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Name"
          class="shrink mt-1"
          contain
          min-width="80"
          src="https://raw.githubusercontent.com/agprop-india/agprop-data/81b26e4b4b66d6028ea8c36cded435f5a7260d67/images/agprop%20logo.svg"
          lazy-src="https://raw.githubusercontent.com/agprop-india/agprop-data/81b26e4b4b66d6028ea8c36cded435f5a7260d67/images/agprop%20logo.svg"
          width="50"
        />
      </div>
    </router-link>
    <span class="px-4">
      <v-btn
        depressed
        text
        class="mr-1 hidden-sm-and-down"
        color="#0048d0"
        style="text-transform: capitalize"
        rounded
        small
        to="/properties"
        >Properties</v-btn
      >
      <v-btn
        depressed
        text
        class="mr-1 hidden-sm-and-down"
        color="#0048d0"
        style="text-transform: capitalize"
        rounded
        small
        to="/products"
        >Products</v-btn
      >
      <v-btn
        depressed
        text
        class="mr-1 hidden-sm-and-down"
        color="#0048d0"
        style="text-transform: capitalize"
        rounded
        small
        to="/requirements"
        >Brand Requirements</v-btn
      >
      <v-btn
        depressed
        text
        class="mr-1 hidden-sm-and-down"
        color="#0048d0"
        style="text-transform: capitalize"
        rounded
        small
        to="/services"
        >Services</v-btn
      >
      <v-btn
        depressed
        text
        class="mr-1 hidden-sm-and-down"
        color="#0048d0"
        style="text-transform: capitalize"
        rounded
        small
        to="/about"
        >About</v-btn
      >
      <v-btn
        depressed
        text
        class="mr-1 hidden-sm-and-down"
        color="#0048d0"
        style="text-transform: capitalize"
        rounded
        small
        to="/contact"
        >Contact</v-btn
      >
    </span>

    <v-spacer></v-spacer>
    <!-- <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fab
          icon
          small
          v-bind="attrs"
          v-on="on"
          text
          class="hidden-sm-and-down"
          color="#0048d0"
          style="text-transform: capitalize"
          @click="reload()"
        >
          <v-icon>mdi-reload</v-icon>
        </v-btn>
      </template>
      <span>Hard Reload</span>
    </v-tooltip> -->

    <!-- <v-btn depressed text class="mr-1 hidden-sm-and-down" rounded small
      >Careers</v-btn
    > -->
    <v-btn
      @click="login"
      v-if="currentUser == null"
      color="#0048d0"
      depressed
      class="mr-2 hidden-sm-and-down"
      dark
      rounded
      small
      >Login</v-btn
    >
    <!-- <v-avatar size="40" >
      <img :src="currentUser.photoURL" :alt="currentUser.displayName" />
    </v-avatar> -->
    <ProfileMenuVue :userInfo="currentUser" v-if="currentUser != null" />
    <!-- {{ currentUser }} -->
  </v-app-bar>
</template>

<script>
import ProfileMenuVue from "./ProfileMenu.vue";

export default {
  name: "AppToolbar",
  components: {
    ProfileMenuVue,
  },
  data: () => ({
    // currentUser: null
  }),
  mounted() {
    this.$store.dispatch("authStore/FetchCurrentUser");
  },
  computed: {
    currentUser: {
      get() {
        return this.$store.state.authStore.currentUser;
      },
    },
  },
  methods: {
    reload() {
      window.location.reload();
    },
    toggleDrawer() {
      this.$store.commit("TOGGLE_DRAWER");
    },
    login() {
      // login
      this.$store.dispatch("authStore/LoginWithGoogle");
    },
  },
};
</script>

<style></style>
