import { getQueryString } from "@/utils/helper";
import { apiRequest } from "@/utils/httpRequest";
const state = {
  filterValue: {
    filter: false,
    city: "",
    brand_id: "",
    pincode: "",
    area_name: "",
  },
  brandRequirements: [],
};

const getters = {
  GET_BRAND_REQUIREMENTS: (state) => state.brandRequirements,
  GET_FILTER_VALUE: (state) => state.filterValue,
};

const mutations = {
  SET_FILTER_VALUE: (state, payload) => {
    state.filterValue = payload;
  },
  SET_ALL_BRAND_REQUIREMENTS: (state, payload) =>
    (state.brandRequirements = payload),
  DO_NOTHING: (state) => state,
};
const actions = {
  UPDATE_FILTER_VALUE({ commit }, filterValue) {
    commit("SET_FILTER_VALUE", filterValue);
  },
  /**
   * @description Get All BRAND_REQUIREMENT
   * @param {Object} data - BRAND_REQUIREMENT Object
   * @returns Promise
   */

  async FETCH_ALL_BRAND_REQUIREMENT({ commit }, options) {
    return new Promise(async (resolve, reject) => {
      let params = {};
      if (options.all) {
        params = options;
      } else {
        const { sortBy, sortDesc, page, itemsPerPage } = options;
        let sort_by = sortBy[0];
        let sort_order = sortDesc[0] ? "DESC" : "ASC";
        params = {
          page: page,
          sort_by: sort_by,
          sort_order: sort_order,
          per_page: itemsPerPage,

        };
      }
      let url = getQueryString(params).substring(1);
      apiRequest(`/brand-requirements/public?${url}`, "get", {})
        .then((res) => {
          if (res.data.success) {
            commit("SET_ALL_BRAND_REQUIREMENTS", res?.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * @description Get All BRAND REQUIREMENTS By BRAND ID
   * @param {Object} data - BRAND_REQUIREMENT Object
   * @returns Promise
   */
  async FETCH_BRAND_REQUIREMENTS_BY_BRAND_ID({ commit }, options) {
    return new Promise(async (resolve, reject) => {
      let params = {};
      if (options.all) {
        params = options;
      } else {
        const { page, itemsPerPage } = options;
        params = {
          page: page,
          per_page: itemsPerPage
        };
      }
      let id = options.id;
      let url = getQueryString(params).substring(1);
      apiRequest(`/brand-requirements/public/brand/${id}?${url}`, "get", {})
        .then((res) => {
          if (res.data.success) {
            commit("SET_ALL_BRAND_REQUIREMENTS", res?.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description Get a Single Brand Requirement Info
   * @param {Number} id - Brand Requirement Id
   * @returns Promise
   */
  async FETCH_BRAND_REQUIREMENT_BY_ID({ commit }, id) {
    return new Promise(async (resolve, reject) => {
      apiRequest(`/brand-requirements/public/${id}`, "get", {})
        .then((res) => {
          if (res.data.success) {
            commit("DO_NOTHING");
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
  * @description Fetch Brand Requirement By brand Id
  * @param {Number} id - brand id
  * @returns Promise
  */
  // async FETCH_BRAND_REQUIREMENT_BY_BRAND_ID({ commit }, options) {
  //   return new Promise(async (resolve, reject) => {
  //     let params = "";
  //     if (options.all) {
  //       params = {
  //         all: "true",
  //         city: state.filterValue.city,
  //         brand_id: state.filterValue.brand_id,
  //         pincode: state.filterValue.pincode,
  //         area_name: state.filterValue.area_name,
  //       }
  //     } else {
  //       const { sortBy, sortDesc, page, itemsPerPage } = options;
  //       let sort_by = sortBy[0];
  //       let sort_order = sortDesc[0] ? "DESC" : "ASC";
  //       params = {
  //         page: page,
  //         sort_by: sort_by,
  //         sort_order: sort_order,
  //         per_page: itemsPerPage,
  //         city: state.filterValue.city,
  //         brand_id: state.filterValue.brand_id,
  //         pincode: state.filterValue.pincode,
  //         area_name: state.filterValue.area_name,
  //       };
  //     }
  //     params = { ...params, brand_id: options.id };
  //     let url = getQueryString(params).substring(1);
  //     apiRequest(`/brand-requirements/public/filter?${url}`, "get", {})
  //       .then((res) => {
  //         if (res.data.success) {
  //           commit("SET_ALL_BRAND_REQUIREMENTS", res.data?.data?.data);
  //           resolve(res);
  //         }
  //       })
  //       .catch((e) => {
  //         reject(e);
  //       });
  //   });
  // },
  /**
  * @description Filter Brand Requirement Info
  * @param {Number} id - Brand Requirement Id
  * @returns Promise
  */
  async FILTER_BRAND_REQUIREMENT({ commit }, options) {
    console.log(options, 'options');
    return new Promise(async (resolve, reject) => {
      let params = "";
      if (options.all) {
        params = {
          all: "true",
          city: state.filterValue.city,
          brand_id: state.filterValue.brand_id,
          pincode: state.filterValue.pincode,
          area_name: state.filterValue.area_name,
        }
      } else {
        const { sortBy, sortDesc, page, itemsPerPage } = options;
        let sort_by = sortBy[0];
        let sort_order = sortDesc[0] ? "DESC" : "ASC";
        params = {
          page: page,
          sort_by: sort_by,
          sort_order: sort_order,
          per_page: itemsPerPage,
          city: state.filterValue.city,
          brand_id: state.filterValue.brand_id,
          pincode: state.filterValue.pincode,
          area_name: state.filterValue.area_name,
        };
      }
      params = { ...params };
      let url = getQueryString(params).substring(1);
      apiRequest(`/brand-requirements/public/filter?${url}`, "get", {})
        .then((res) => {
          if (res.data.success) {
            commit("SET_ALL_BRAND_REQUIREMENTS", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description Get all Brand name in Brand Requirement Info
   * @returns Promise
   */
  async FETCH_ALL_BRANDS({ commit }) {
    return new Promise(async (resolve, reject) => {
      apiRequest(`/brands/public`, "get", {})
        .then((res) => {
          if (res.data.success) {
            commit("DO_NOTHING");
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
