<template>
  <v-app style="background-color: #F5F8FC">
    <v-snackbar
        v-model="snackWithButtons"
        bottom
        right
        :timeout="5000"
      >
        {{ snackWithBtnText }}
        <template v-slot:action="{ attrs }">
          <v-btn
            text
            color="#00f500"
            v-bind="attrs"
            @click.stop="refreshApp"
          >
            {{ snackBtnText }}
          </v-btn>
          <v-btn
            icon
            class="ml-4"
            @click="snackWithButtons = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    <AppToolbarVue />
    <AppDrawerVue />
    <v-main>
      <v-slide-y-reverse-transition>
        <router-view v-show="show" />
      </v-slide-y-reverse-transition>
    </v-main>
    <AppFooterVue />
  </v-app>
</template>

<script>
import AppDrawerVue from "./components/core/AppDrawer.vue";
import AppFooterVue from "./components/core/AppFooter.vue";
import AppToolbarVue from "./components/core/AppToolbar.vue";
export default {
  name: "App",
  components: {
    AppToolbarVue,
    AppFooterVue,
    AppDrawerVue,
  },
  data: () => ({
    show: false,
    //
    refreshing: false,
    registration: null,
    snackBtnText: "",
    snackWithBtnText: "",
    snackWithButtons: false,
  }),
  async created() {
    this.show = true;
    await this.$store.dispatch('authStore/FetchCurrentUser')
    // sw
    // Listen for swUpdated event and display refresh snackbar as required.
    document.addEventListener('swUpdated', this.showRefreshUI, { once: true });
    // Refresh all open app tabs when a new service worker is installed.
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) return;
        this.refreshing = true;
        window.location.reload();
      });
    }
  },
  methods: {
    showRefreshUI(e) {
      // Display a snackbar inviting the user to refresh/reload the app due
      // to an app update being available.
      // The new service worker is installed, but not yet active.
      // Store the ServiceWorkerRegistration instance for later use.
      this.registration = e.detail;
      this.snackBtnText = 'Refresh';
      this.snackWithBtnText = 'New version available!';
      this.snackWithButtons = true;
    },
    refreshApp() {
      this.snackWithButtons = false;
      // Protect against missing registration.waiting.
      if (!this.registration || !this.registration.waiting) { return; }
      this.registration.waiting.postMessage('skipWaiting');
    },
  },
};
</script>
