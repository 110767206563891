import { getQueryString } from "@/utils/helper";
import { apiRequest } from "@/utils/httpRequest";
const loadPageValue = () => {
  const currentPage = JSON.parse(localStorage.getItem("currentPage"));
  return currentPage ? currentPage : 1
};
const loadItemPerPage = () => {
  const itemsPerPage = JSON.parse(localStorage.getItem("itemsPerPage"));
  return itemsPerPage ? itemsPerPage : 10
};
const state = {
  properties: [],
  currentPage: loadPageValue(),
  itemsPerPage: loadItemPerPage(),
};

const getters = {
  GET_PROPERTIES: (state) => state.properties,
};

const mutations = {
  DO_NOTHING: (state) => state,
  SET_ALL_PROPERTIES: (state, payload) => (state.properties = payload),
  ADD_NEW_PROPERTIES: (state, payload) => {
    state.properties.unshift(payload);
  },
  SET_CURRENT_PAGE: (state, page) => {
    state.currentPage = page;
    localStorage.setItem("currentPage", JSON.stringify(page));
  },
  SET_CURRENT_ITEM_PER_PAGE: (state, payload) => {
    state.itemsPerPage = payload;
    localStorage.setItem("itemsPerPage", JSON.stringify(payload));
  },
};
const actions = {
  /**
   * @description Get All Properties
   * @param {Object} data - Properties Object
   * @returns Promise
   */
  async FETCH_ALL_PROPERTIES({ commit }, options) {
    return new Promise(async (resolve, reject) => {
      let params = {};
      if (options.all) {
        params = options;
      } else {
        params = {
          page: options.page,
          per_page: options.itemsPerPage,
        };
      }
      let url = getQueryString(params).substring(1);
      apiRequest(`/property/public?${url}`, "get", {})
        .then((res) => {
          if (res.data.success) {
            commit("SET_ALL_PROPERTIES", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description Get single Properties
   * @param {Object} data - Properties ID
   * @returns Promise
   */
  async FETCH_PROPERTY({ commit }, id) {
    return new Promise(async (resolve, reject) => {
      apiRequest(`/property/public/${id}`, "get", {})
        .then((res) => {
          if (res.data.success) {
            commit("DO_NOTHING");
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
 * @description Search property via Pincode
 * @param {Object} data - pincode
 * @returns Promise
 */
  async SEARCH_PROPERTY({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      apiRequest(`/property/public-filter-properties?pincode=${payload}`, "get")
        .then((res) => {
          if (res.data.success) {
            commit("DO_NOTHING");
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description Updload Images
   * @param {Object} data - Update Images
   * @returns Promise
   */
  async UPLOAD_FILE_BY_USER({ commit }, data) {
    const formData = new FormData();
    formData.append("file", data.file);
    formData.append("dirType", data.dirType);
    formData.append("id", data.id);
    return new Promise(async (resolve, reject) => {
      apiRequest("/user-files/upload-properties/public", "post", formData)
        .then((res) => {
          if (res.data.success) {
            commit("DO_NOTHING");
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * @description Add Properties
   * @param {Object} data - Properties data
   * @returns Promise
   */
  async ADD_PROPERTY({ commit }, data) {
    return new Promise(async (resolve, reject) => {
      apiRequest(`/brand-requirements/public/add-property`, "post", data)
        .then((res) => {
          if (res.data.success) {
            commit("DO_NOTHING", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * @description Add Image in the properties
   * @param {Object} data - Properties data
   * @returns Promise
   */
  async ADD_IMAGE_PROPERTY({ commit }, data) {
    return new Promise(async (resolve, reject) => {
      apiRequest(
        `/brand-requirements/public/add-property/${data.id}`,
        "put",
        data
      )
        .then((res) => {
          if (res.data.success) {
            commit("DO_NOTHING", data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * @description Add Conatct Info properties
   * @param {Object} data - Properties data
   * @returns Promise
   */

  async CONTACT_PROPERTY({ commit }, data) {
    return new Promise(async (resolve, reject) => {
      apiRequest(`/property-contacts`, "post", data)
        .then((res) => {
          if (res.data.success) {
            commit("ADD_NEW_PROPERTIES", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  /**
   * @description Filter Properties properties
   * @param {Object} data - Properties data
   * @returns Promise
   */
  async FILTER_PROPERTIES({ commit }, options) {
    return new Promise(async (resolve, reject) => {
      let params = {};
      if (options.all) {
        params = options;
      } else {
        params = {
          page: options.page,
          per_page: options.itemsPerPage,
        };
      }
      delete options.sortBy, delete options.sortDesc, delete options.groupBy;
      delete options.groupDesc;
      delete options.multiSort;
      delete options.mustSort;
      delete options.itemsPerPage
      params = { ...params, ...options };
      let url = getQueryString(params).substring(1);
      apiRequest(`/property/public-filter-properties?${url}`, "get", {})
        .then((res) => {
          if (res.data.success) {
            commit("SET_ALL_PROPERTIES", res.data?.data?.data);
            resolve(res);
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * @description update current page
   * @param {Object} data - page no.
   * @returns Promise
   */
  UPDATE_CURRENT_PAGE({ commit }, page) {
    commit('SET_CURRENT_PAGE', page);
  },
  /**
   * @description update itemPerPage
   * @param {Object} data - items per page
   * @returns Promise
   */
  UPDATE_CURRENT_ITEM_PER_PAGE({ commit }, payload) {
    commit('SET_CURRENT_ITEM_PER_PAGE', payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
