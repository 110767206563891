export const getQueryString = (queryObject) => {
  return Object.entries(queryObject)
    .filter((filter) => {
      return filter[1];
    })
    .reduce((qString, filterField) => {
      return qString.concat(
        "&" +
          filterField[0]
            .split(/(?=[A-Z])/)
            .join("_")
            .toLowerCase() +
          "=" +
          filterField[1]
      );
    }, "");
};
