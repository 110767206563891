<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="true"
    :nudge-width="100"
    :max-width="220"
    offset-y
    transition="slide-y-transition"
    bottom
    left
    rounded
  >
    <template v-slot:activator="{ on: menu }">
      <v-avatar size="38" style="cursor: pointer">
        <img
          v-on="{ ...menu }"
          :src="
            userInfo.photoURL
              ? userInfo.photoURL
              : 'https://raw.githubusercontent.com/DevFest-India/website-data/master/defaultavatar.png'
          "
          alt="John"
          style="border: 3px solid #0048d0"
        />
      </v-avatar>
    </template>

    <v-card class="elevation-0" outlined flat>
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <img
              :src="
                userInfo.photoURL
                  ? userInfo.photoURL
                  : 'https://raw.githubusercontent.com/DevFest-India/website-data/master/defaultavatar.png'
              "
            />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="google-font">{{
              userInfo.displayName || "User-Name"
            }}</v-list-item-title>
            <v-list-item-subtitle>{{
              userInfo.email || "User-Email"
            }}</v-list-item-subtitle>
            <!-- <v-list-item-subtitle>Display Profile</v-list-item-subtitle> -->
          </v-list-item-content>
        </v-list-item>

        <!-- <v-list-item to="/profile">
          <v-list-item-avatar>
            <v-icon>mdi-account-box-multiple-outline</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title style="font-size: 100%" class="google-font"
              >Profile</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item> -->
        <v-list-item @click="logout">
          <v-list-item-avatar>
            <v-icon>mdi-logout-variant</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="google-font">Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>
  
  <script>
export default {
  props: ["userInfo"],
  data: () => ({
    menu: false,
  }),
  methods: {
    logout() {
      // logout
      this.$store.dispatch('authStore/LogoutUser')
    },
  },
};
</script>
  