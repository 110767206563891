<template>
  <v-container fluid>
    <v-row class="px-0 mb-12">
      <v-col md="12" class="">
        <div style="background-color: white; border-radius: 12px">
          <v-container fluid class="pa-10">
            <v-row justify="center" align="center">
              <v-col md="4">
                <h1 style="color:#0048d0">PropVision</h1>
                <p>
                  Introducing our powerful brand property management tool! With
                  this all-in-one solution, you can efficiently manage every
                  aspect related to your brand property.
                </p>
                <v-btn
                    class="mt-5"
                    href="https://bit.ly/propvision-interest"
                    rounded
                    target="_blank"
                    depressed
                    color="#0048d0"
                    dark
                    >Request for more Info</v-btn
                  >
              </v-col>
              <v-col md="8">
                <v-img
                  :src="product1.image"
                  style="border-radius: 12px; width: 100%"
                  width="90"
                ></v-img>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-col>
    </v-row>
    <v-row class="px-0" style="margin-top: 5rem">
      <v-col class="py-0 my-1" md="4" style="min-height: 300px;">
        <v-img
            :src="product1.image1"
            style="
              width: 100%;
              border-radius: 12px;
            "
          />
      </v-col>
      <v-col md="8" class="py-0 my-1" style="min-height: 300px;">
        <div
          style="background-color: #c8ccd1; border-radius: 12px; "
          class="img_div"
        >
          <v-img
            :src="product1.image2"
            style="height: 100%"
            class="px-6 py-4 align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.8)"
          >
            <div
              class="pa-10"
              style="
                background-color: white;
                border-radius: 12px;
                opacity: 0.9;
                padding-top: 10%;
              "
            >
              <p class="ma-0"><b>PropVision</b></p>
              <span>
                is an effective tool that can conveniently sort your preferences
                for brand properties. Simplifies management of multiple assets
                by servicing your properties with prerequisite demands. Use our
                AI powered customisation tool to unlock your real estate
                potential.
              </span>
              <br>
              <br>
              <ul>
                <li>Requirements Management</li>
                <li>Property Management</li>
                <li>Magic URLs</li>
                <li>Access and Control</li>
              </ul>
            </div>
          </v-img>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    activeBtn: 1,
    product1: {
      title: "Brand Dashboard",
      desc: "Title 1 Desc",
      image: require("@/assets/img/Products/propvision.jpg"),
      image1: require("@/assets/img/Products/mobile.jpg"),
      image2: require("@/assets/img/IMG3.svg"),
    },
  }),
};
</script>

<style>
.img_div {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.img_cont {
  transition: transform 0.5s;
}
.img_cont:hover {
  transform: scale(1.1);
}
</style>
