import axios from "axios";
const apiURL = process.env.VUE_APP_SERVICE_URL;

// console.log('Base URL=>', apiURL);

export const apiRequest = (url, type, data) => {
  return new Promise((resolve, reject) => {
    try {
      const res = axios({
        method: type,
        url: apiURL+url,
        // headers:{'Access-Control-Allow-Origin':'false'},
        data: data,
      });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};
