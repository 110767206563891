import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAHKZsPT2kmnuBlq0KU-gSK3OBzFss40MI",
  authDomain: "agprop-web.firebaseapp.com",
  projectId: "agprop-web",
  storageBucket: "agprop-web.appspot.com",
  messagingSenderId: "698890525825",
  appId: "1:698890525825:web:14dca0c7a5437a41df8180",
  measurementId: "G-KZKTKWLZRF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
const db = getFirestore(app)

export {db, auth}