import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/properties",
    name: "properties",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PropertiesPage.vue"),
  },
  {
    path: "/properties/:id",
    name: "property-info-page",
    component: () =>
      import(
        /* webpackChunkName: "property-info-page" */ "../views/PropertyInfoPage.vue"
      ),
  },
  {
    path: "/products",
    name: "products",
    component: () =>
      import(/* webpackChunkName: "products" */ "../views/ProductsPage.vue"),
  },

  {
    path: "/requirements",
    name: "requirement-page",
    component: () =>
      import(/* webpackChunkName: "brands" */ "../views/BrandPage.vue"),
  },
  {
    path: "/requirement/:brand_name/:id",
    name: "brand-requirement",
    component: () =>
      import(/* webpackChunkName: "brand-requirements" */ "../views/BrandRequirementsPage.vue"),
  },
  {
    path: "/brand-requirements/:id",
    name: "brand-requirement-page",
    component: () =>
      import(/* webpackChunkName: "brand-requirement-page" */ "../views/AddPropertyPage.vue"),
  },
  {
    path: "/services",
    name: "services",
    component: () =>
      import(/* webpackChunkName: "services" */ "../views/ServicesPage.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/ContactPage.vue"),
  },
  {
    path: "/team",
    name: "team",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/TeamPage.vue"),
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () =>
      import(/* webpackChunkName: "products" */ "../views/PrivacyPolicyPage.vue"),
  },
  {
    path: "/terms-conditions",
    name: "term-conditions",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/TermConditionsPage.vue"),
  },
  {
    path: "/prop-vision",
    name: "prop-vision",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/PropVision.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
});

export default router;
