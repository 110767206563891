<template>
  <v-main>
    <v-container fluid>
      <v-row justify="center" align="center" class="bg pt-6">
        <v-col md="10">
          <!-- Content -->
          <v-container fluid>
            <!-- Hero -->
            <v-row align="center">
              <v-col md="6" cols="12" align-self="start" >
                <h1 class="display-3" style="color: #0048d0">
                  Helping You Make the Right Move
                </h1>
                <p class="pt-8">
                  Experience our consumer-centric brand property management tool! This comprehensive solution empowers you to efficiently handle your brand property. From managing properties to meeting brand requirements, our tool has got you covered. 
                  <!-- It even offers personalised property suggestions tailored to your specific needs. Simplify your brand property management, make informed decisions effortlessly, and elevate your brand expectations. Don't miss out - give it a try now! -->

                </p>
                <v-btn
                  style="border-radius: 12px; text-transform: capitalize"
                  depressed
                  color="#BBC0C6"
                  class="mr-3"
                  to="/products"
                  >Products</v-btn
                >
                <v-btn
                  style="border-radius: 12px; text-transform: capitalize"
                  depressed
                  color="white"
                  to="/about"
                  >Learn More
                  <v-icon right>mdi-arrow-right-thin</v-icon>
                </v-btn>
              </v-col>
              <v-col md="6" cols="12">
                <div
                  style="
                    height: 500px;
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                  "
                >
                  <img
                    src="@/assets/img/Mobile.svg"
                    style="max-width: auto%; height: 100%"
                  />
                </div>
              </v-col>
            </v-row>
            <!-- Hero -->
          </v-container></v-col
        ></v-row
      >
      <v-row justify="center" align="center">
        <v-col md="10" cols="12">
          <!-- Content -->
          <v-container fluid>
            <!-- Summery -->
            <v-row justify="center" align="center" class="my-12 pt-12">
              <v-col md="4" cols="12">
                <v-img
                  style="margin-top: -5rem"
                  :src="require('@/assets/img/indiamap.svg')"
                ></v-img>
              </v-col>
              <v-col md="8" cols="12">
                <v-container fluid>
                  <v-row>
                    <v-col
                      md="6"
                      cols="6"
                      v-for="(item, index) in contents"
                      :key="index"
                    >
                      <v-container fluid>
                        <v-row>
                          <v-col md="4">
                            <p
                              class="h1"
                              style="font-size: 220%; color: #0048d0"
                            >
                              {{ item.count }}
                            </p>
                          </v-col>
                          <v-col md="8">
                            <p class="mb-0" style="font-size: 110%">
                              <b>{{ item.title }}</b>
                            </p>
                            <p class="mb-0">{{ item.desc }}</p>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
            <!-- Summery -->

            <!-- Partners -->
            <!-- <v-row>
              <v-col md="12">
                <v-img :src="require('@/assets/img/partners.svg')"></v-img>
              </v-col>
            </v-row> -->
            <!-- Partners -->

            <!-- Products -->
            <v-row class="my-12">
              <v-col md="12">
                <ProductOverviewVue />
              </v-col>
            </v-row>
            <!-- Products -->
            <!-- Broker Network -->
            <v-row
              justify="center"
              align="center"
              style="
                background-color: #202020;
                color: white;
                border-radius: 12px;
                margin: 5rem 0.5rem;
              "
              ><v-col md="12" class="pa-10">
                <v-row>
                  <v-col md="4" cols="12"><h3>Our Vision</h3></v-col>
                  <v-col md="12"  cols="12">
                    <p>Our vision is to strive for revolutionising the Indian real estate sector through machine learning and deep research tools that gives you the ideal market overview to make informed decisions. Help us democratise this technology to make businesses adaptable for the modern age. </p>

                    <v-btn style="border-radius: 12px; text-transform: capitalize"
                  depressed
                  color="white"
                  to="/services">Our Services</v-btn>
                  </v-col>
                </v-row>
              </v-col>

              
              <!-- <v-col md="4" class="py-12 pl-8">
                <v-row>
                  <v-col md="4" class="pb-0 mb-0">
                    <p
                      style="
                        background-color: #0048d0;
                        height: 80px;
                        width: 80px;
                        border-radius: 12px;
                        margin: 0;
                      "
                    ></p
                  ></v-col>
                  <v-col md="4"><h3>Broker network</h3></v-col>
                  <v-col md="12">
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                    Voluptates voluptatem ex, consequatur odit quam deserunt
                    itaque debitis delectus tempore optio c
                  </v-col>
                </v-row>
              </v-col> -->
            </v-row>
            <!-- Broker Network -->
            <!-- Imgs -->
            <v-row align="center" class="my-12 px-2">
              <v-col md="6"
                ><div class="img_div">
                  <v-img
                    class="img_cont"
                    style="border-radius: 12px;"
                    src="@/assets/img/IMG1.svg"
                  ></v-img></div
              ></v-col>
              <v-col md="6"
                ><div class="img_div">
                  <v-img
                    class="img_cont"
                    style="border-radius: 12px;"
                    src="@/assets/img/IMG2.svg"
                  ></v-img></div
              ></v-col>
            </v-row>
            <!-- Imgs -->
            <!-- Broker Network 2 -->
            <!-- <v-row justify="space-between" align="center" class="my-12">
              <v-col cols="12" md="6" v-for="i in 2" :key="i">
                <v-container
                  fluid
                  style="background-color: #ffffff; border-radius: 20px"
                  ><v-row justify="space-between" class="px-3">
                    <v-col md="8" cols="8" class="py-5">
                      <p class="my-1">
                        <strong>Brokers Network</strong>
                      </p>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Vestibulum gravida placerat dictum. Sed sagittis
                        accumsan d lor ut malesuada. Duis sit amet placerat
                        quam.
                      </p>
                    </v-col>
                    <v-col md="3" cols="4" class="text-center my-auto">
                      <p
                        style="
                          background-color: #0048d0;
                          height: 90px;
                          width: 90px;
                          border-radius: 12px;
                        "
                      ></p>
                      <p
                        style="
                          background-color: #88c2ec;
                          height: 50px;
                          width: 50px;
                          border-radius: 12px;
                          margin-left: -2rem;
                          margin-top: -5rem;
                        "
                      ></p>
                    </v-col> </v-row
                ></v-container>
              </v-col>
            </v-row> -->
            <!-- Broker Network  -->

            <!-- key points -->
            <!-- <v-row align="center" class="mx-0 my-12">
              <v-col md="6" class="pa-0">
                <p style="font-size: 35px; color: #0048d0">Key Points</p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vestibulum gravida placerat dictum. Sed sagittis accumsan d
                  lor ut malesuada. Duis sit amet placerat quam.
                </p>
              </v-col>
              <v-col md="6" align-self="end" class="float-right">
                <div class="float-right">
                  <v-btn
                    class="px-8"
                    depressed
                    style="
                      background-color: #0048d0;
                      color: white;
                      border-radius: 12px;
                      text-transform: capitalize;
                    "
                    >Products</v-btn
                  >
                </div>
              </v-col>
            </v-row> -->
            <!-- key points -->
          </v-container>
          <!-- Content -->
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid class="ma-0 pa-0">
      <div class="marquee">
        <div class="marquee__group">
          <span
            class="white pa-7 my-4"
            style="border-radius: 15px; width: 450px; height:150px"
            >{{ keypoints[0] }}</span
          >
          <span
            class="white pa-7 my-4"
            style="border-radius: 15px; width: 450px; height:150px"
            >{{ keypoints[1] }}</span
          >
          <span
            class="white pa-7 my-4"
            style="border-radius: 15px; width: 450px; height:150px"
            >{{ keypoints[2] }}</span
          >
          <span
            class="white pa-7 my-4"
            style="border-radius: 15px; width: 450px; height:150px"
            >{{ keypoints[3] }}</span
          >
          <span
            class="white pa-7 my-4"
            style="border-radius: 15px; width: 450px; height:150px"
            >{{ keypoints[4] }}</span
          >
        </div>

        <div aria-hidden="true" class="marquee__group">
          <span
            class="white pa-7 my-4"
            style="border-radius: 15px; width: 450px; height:150px"
            >{{ keypoints[0] }}</span
          >
          <span
            class="white pa-7 my-4"
            style="border-radius: 15px; width: 450px; height:150px"
            >{{ keypoints[1] }}</span
          >
          <span
            class="white pa-7 my-4"
            style="border-radius: 15px; width: 450px; height:150px"
            >{{ keypoints[2] }}</span
          >
          <span
            class="white pa-7 my-4"
            style="border-radius: 15px; width: 450px; height:150px"
            >{{ keypoints[3] }}</span
          >
          <span
            class="white pa-7 my-4"
            style="border-radius: 15px; width: 450px; height:150px"
            >{{ keypoints[4] }}</span
          >
        </div>
      </div>
      <div class="marquee marquee--reverse">
        <div class="marquee__group">
          <span
            class="white pa-7 my-4"
            style="border-radius: 15px; width: 450px; height:150px"
            >{{ keypoints[5] }}</span
          >
          <span
            class="white pa-7 my-4"
            style="border-radius: 15px; width: 450px; height:150px"
            >{{ keypoints[6] }}</span
          >
          <span
            class="white pa-7 my-4"
            style="border-radius: 15px; width: 450px; height:150px"
            >{{ keypoints[7] }}</span
          >
          <span
            class="white pa-7 my-4"
            style="border-radius: 15px; width: 450px; height:150px"
            >{{ keypoints[8] }}</span
          >
          <span
            class="white pa-7 my-4"
            style="border-radius: 15px; width: 450px; height:150px"
            >{{ keypoints[9] }}</span
          >
        </div>

        <div aria-hidden="true" class="marquee__group">
          <span
            class="white pa-7 my-4"
            style="border-radius: 15px; width: 450px; height:150px"
            >{{ keypoints[5] }}</span
          >
          <span
            class="white pa-7 my-4"
            style="border-radius: 15px; width: 450px; height:150px"
            >{{ keypoints[6] }}</span
          >
          <span
            class="white pa-7 my-4"
            style="border-radius: 15px; width: 450px; height:150px"
            >{{ keypoints[7] }}</span
          >
          <span
            class="white pa-7 my-4"
            style="border-radius: 15px; width: 450px; height:150px"
            >{{ keypoints[8] }}</span
          >
          <span
            class="white pa-7 my-4"
            style="border-radius: 15px; width: 450px; height:150px"
            >{{ keypoints[9] }}</span
          >
        </div>
      </div>
    </v-container>
    <v-container fluid>
      <v-row justify="center" align="center" class="mt-12">
        <v-col md="10">
          <v-container fluid>
            <!-- <v-row
              align="center"
              style="background-color: #c8ccd1; border-radius: 20px"
              class="mb-12"
            >
              <v-col md="4" cols="12" class="pa-5 pl-8">
                <p class="my-1"><strong>Brokers Network</strong></p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vestibulum gravida placerat dictum. Sed sagittis accumsan d
                  lor ut malesuada. Duis sit amet placerat quam.
                </p>
              </v-col>
              <v-col md="4" cols="12" class="pa-5 pl-8">
                <p class="my-1"><strong>Brokers Network</strong></p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vestibulum gravida placerat dictum. Sed sagittis accumsan d
                  lor ut malesuada. Duis sit amet placerat quam.
                </p>
              </v-col>
            </v-row> -->
            <!-- Testimonials -->
            <!-- <v-row
              class="my-12"
              style="background-color: #ffffff; border-radius: 20px"
            >
              <v-col md="6" class="pa-6">
                <p style="font-size: 35px; color: #0048d0">Testimonials</p>
                <p style="font-size: 14px">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Vestibulum gravida placerat dictum. Sed sagittis accumsan d
                  lor ut malesuada. Duis sit amet placerat quam.
                </p>
              </v-col>
              <v-col md="6"></v-col>
            </v-row>
            <v-row class="my-12 px-0">
              <v-col md="12" class="px-0">
                <v-container fluid class="px-0">
                  <v-row>
                    <v-col
                      v-for="(i, index) in 4"
                      :key="index"
                      md="3"
                      class="ma-0 pa-3"
                    >
                      <v-card
                        class="mx-auto pa-8"
                        min-height="300px"
                        max-height="430px"
                        style="border-radius: 20px; border: #ffffff"
                        outlined
                      >
                        <v-avatar
                          size="70"
                          color="grey"
                          style="border-radius: 12px"
                        >
                        </v-avatar>
                        <p style="font-size: 120%" class="mt-6 mb-2">Title</p>
                        <p class="mb-0">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit. Dolorem nihil adipisci numquam temporibus quis.
                        </p>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row> -->
            <!-- Testimonials -->
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import ProductOverviewVue from "@/components/home/ProductOverview.vue";

export default {
  name: "HomePage",
  components: {
    ProductOverviewVue,
  },
  created(){
    document.title = "agprop"
  },
  data: () => ({
    contents: [
      {
        title: "Broker Network",
        desc: "is expanding everyday. Optimising communication channels for better networking in Tier 2/3 cities. ",
        count: "1500+",
      },
      {
        title: "National & International Brands",
        desc: "approaching our client services to expand their operations in Indian cities. Trusted us to secure their brand expansion in growing cities. ",
        count: "50+",
      },
      {
        title: "National Developers",
        desc: "is expanding everyday. Optimising communication channels for better networking in Tier 2/3 cities. ",
        count: "100+",
      },
      {
        title: "Sq. ft. area",
        desc: "Properties available for premium High Street Retail across the country. Vast options to browse through to pick what works perfectly for you. ",
        count: "1 Cr+",
      },
      {
        title: "Landlords",
        desc: " connecting with us to lease their properties. Designing the optimal lease agreements to build reliability.",
        count: "2000+",
      },
      {
        title: "Commercial Projects",
        desc: "undertaken across the country for expansion, diversification and growth. Satisfied clientele that can unlock business opportunities. ",
        count: "350+",
      },
    ],

    keypoints: [
      "Our vision is to foster a culture of innovation. Embracing cutting-edge and sustainable technologies. ",
      "We aim to transform the Indian real estate sector by leveraging research based insights to provide an ideal market overview.",
      "Our tools empower businesses with the necessary information to make informed decisions. Enabling businesses to navigate their asset management and investment portfolio with confidence. ",
      "We possess expertise in machine learning and utilise it to develop intelligent tools that adapt to the dynamic nature of the real estate industry.",
      "We employ extensive research tools to gain an in-depth understanding of the real estate market. Equipping businesses with unparalleled knowledge and insight into market trends to maximise returns. ",
      "Our vision is to democratise this advanced technology, making it accessible and beneficial to businesses of all sizes, allowing them to adapt and thrive in the modern age.",
      "We prioritise the unique needs of businesses and design our tools with a business-friendly approach, ensuring they are practical, user-friendly, and aligned with industry requirements.",
      "Our tools streamline processes, automate repetitive tasks, and optimise operations, improving overall efficiency and productivity for businesses in the real estate sector.",
      "We continuously invest in research and development to stay ahead of industry trends, providing businesses with future-proof solutions that keep them at the forefront of the market.",
      "We believe in building strong partnerships with businesses, working together to drive innovation, and collectively shaping the future of the real estate industry in India."
    ],
  }),
};
</script>

<style>
body {
  --space: 2rem;
}

.marquee {
  width: 100%;
  --duration: 50s;
  --gap: var(--space);

  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
}

.marquee__group {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: var(--gap);
  min-width: 100%;
  animation: scroll var(--duration) linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .marquee__group {
    animation-play-state: paused;
  }
}

.marquee--reverse .marquee__group {
  animation-direction: reverse;
  animation-delay: calc(var(--duration) / -2);
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-100% - var(--gap)));
  }
}
.bg {
  background-image: url("../assets/img/Home\ page\ ribbon.svg");
  background-size: 100%;
  background-position-y: bottom;
}
.img_div {
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 12px;
}
.img_cont {
  transition: transform 0.5s;
  border-radius: 12px;
}
.img_cont:hover {
  transform: scale(1.1);
  border-radius: 12px;
}
</style>
